import React from "react"
import LogoWhite from "../../../assets/logo/logo-white.svg"
import Insta from "../../../assets/insta.svg"
import Face from "../../../assets/face.svg"

import { Link } from "gatsby"
import { useWpMenu } from "../../hooks/wp/useWpMenu"
import { useWpColophon } from "../../hooks/wp/useWpColophon"

const Footer = () => {
  const { wpMenu } = useWpMenu()
  const { wpPage } = useWpColophon()

  return (
    <>
      <div className="min-h-[20vh] bg-black text-white px-4 md:px-12 grid md:grid-cols-footer gap-y-12 py-8 relative z-20">
        <div className="flex flex-col justify-end items-start h-full">
          <LogoWhite />
        </div>
        <div className="uppercase flex flex-col">
          {wpMenu?.menuItems?.nodes.map(({ connectedNode }, index) => {
            return (
              <Link
                key={`nav-link-${index}`}
                to={connectedNode.node.uri}
                className=" text-white"
              >
                {connectedNode.node.slug !== "work"
                  ? connectedNode.node.slug
                  : "progetti"}
              </Link>
            )
          })}
          <div className="flex flex-row items-center gap-x-2 pt-4">
            <a href="https://www.facebook.com/cooperativadensa/">
              <Face />
            </a>
            <a href="https://www.instagram.com/cooperativadensa/">
              <Insta />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-y-8">
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: wpPage.colophon.address?.replace("[&#8230;]", ""),
              }}
            />
          </div>
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: wpPage.colophon.contacts?.replace("[&#8230;]", ""),
              }}
            />
          </div>
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: wpPage.colophon.businessInfo?.replace("[&#8230;]", ""),
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
