import React from "react"
import { motion } from "framer-motion"
import { StateContext } from "./StateContext"

const animation = {
  default: {
    rotate: 0,
    opacity: 1,
  },
  top: {
    rotate: -45,
    y: "500%",
  },
  bottom: {
    rotate: 45,
    y: "-860%",
  },
  center: {
    opacity: 0,
  },
}
const Hamburger = ({ menuState, setMenuState }) => {
  return (
    <div className="relative z-[3]">
      <button
        className="flex flex-col justify-between gap-y-1 h-[15px]"
        onClick={() => setMenuState(!menuState)}
      >
        {[0, 1, 2].map(item => {
          return (
            <motion.div
              key={`hamburger-${item}`}
              variants={animation}
              initial="default"
              animate={
                menuState && item === 0
                  ? "top"
                  : menuState && item === 2
                  ? "bottom"
                  : menuState && item === 1
                  ? "center"
                  : !menuState && "default"
              }
              transition={{ type: "tween" }}
              className={`${
                StateContext.isSpecialPage ? "bg-white" : "bg-black"
              } w-[25px] min-h-[1px]`}
            />
          )
        })}
      </button>
    </div>
  )
}

export default Hamburger
