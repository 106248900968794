import React, { useState, useEffect } from "react"
import { useWpMenu } from "../../hooks/wp/useWpMenu"
import { useWpGeneralInfo } from "../../hooks/wp/useWpGeneralInfo"
import { AnimatePresence, motion } from "framer-motion"
import _ from "lodash"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RandomNumber } from "../../functions/random-number"

const animation = {
  close: {
    opacity: 0,
    transition: { duration: 0.4 },
    height: "0",
  },
  open: {
    opacity: 1,
    height: "calc(100vh - 24px)",
  },
}

let positions = new Array(100)
for (let i = 0; i < 100; i++) {
  positions[i] = [
    [RandomNumber(0, 1), RandomNumber(0, 1)],
    [RandomNumber(0, 1), RandomNumber(0, 1)],
  ]
}

const imageAnimations = {
  initial: {
    opacity: 0,
    width: 500,
  },
  animate: {
    opacity: 1,
    width: 750,
  },
  exit: {
    opacity: 0,
    width: 500,
  },
}

const OverlayMenu = ({ location, menuState }) => {
  const { wpMenu } = useWpMenu()
  const { wpPage } = useWpGeneralInfo()

  const [activeElement, setActiveElement] = useState(undefined)

  const [target, setTarget] = useState(location.pathname)
  const [description, setDescription] = useState("")

  useEffect(() => {
    const allNodes = wpMenu?.menuItems?.nodes
    let found_elem = _.findKey(allNodes, function (o) {
      return o.connectedNode.node.uri === target
    })

    setActiveElement(found_elem)
    if (
      allNodes[found_elem]?.connectedNode.node.pageDescription
        .pageDescription === null ||
      allNodes[found_elem] === undefined
    ) {
      setDescription(wpPage.generalInfo.shortMenuDefaultText)
    } else {
      setDescription(
        allNodes[found_elem]?.connectedNode.node.pageDescription.pageDescription
      )
    }
  }, [
    target,
    setDescription,
    wpMenu?.menuItems?.nodes,
    wpPage.generalInfo.shortMenuDefaultText,
  ])

  return (
    <motion.div
      variants={animation}
      initial="close"
      animate={menuState ? "open" : "close"}
      exit="close"
      className="fixed top-0 md:top-4 left-0 md:left-4 w-full md:w-[calc(100vw-2em)] md:h-[calc(100vh-2em)] md:rounded-xl bg-gray-300 p-4 md:p-4 md:px-8 z-50 overflow-hidden"
    >
      <div className="mt-[62px] md:mt-[82px] md:grid md:grid-cols-menu gap-x-8 ">
        <div className="hidden md:block relative">
          {menuState && (
            <AnimatePresence>
              <motion.div
                className="text-small-quote-mobile md:text-small-quote md:pt-6 md:absolute top-0 left-0 "
                key={target}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </AnimatePresence>
          )}
        </div>
        <div className="flex flex-col gap-x-4 gap-y-3 relative">
          {wpMenu?.menuItems?.nodes.map(({ connectedNode }, index) => {
            return (
              <div key={`nav-link-${index}`}>
                <div className="mix-blend-difference">
                  <motion.span
                    onHoverStart={() => setTarget(connectedNode.node.uri)}
                    onHoverEnd={() => setTarget(location.pathname)}
                  >
                    <Link
                      href={connectedNode.node.uri}
                      className="text-nav-link-mobile md:text-nav-link text-white"
                      activeClassName="text-gray-700"
                    >
                      {connectedNode.node.title}
                    </Link>
                  </motion.span>
                </div>
                <AnimatePresence>
                  {connectedNode.node.featuredImage !== null &&
                    index.toString() === activeElement && (
                      <motion.div
                        style={{
                          top: positions[index][0][0] * 50 + "%",
                          left: positions[index][0][1] * 50 + "%",
                          zIndex: -1,
                        }}
                        className={`absolute w-1/2  `}
                        variants={imageAnimations}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{
                          duration: 0.8,
                          ease: [0.6, -0.05, 0.01, 0.9],
                        }}
                      >
                        <GatsbyImage
                          image={getImage(
                            connectedNode.node.featuredImage?.node.localFile
                          )}
                          alt=""
                        />
                      </motion.div>
                    )}
                </AnimatePresence>
              </div>
            )
          })}
        </div>
      </div>
    </motion.div>
  )
}

export default OverlayMenu
