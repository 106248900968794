exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cerca-jsx": () => import("./../../../src/pages/cerca.jsx" /* webpackChunkName: "component---src-pages-cerca-jsx" */),
  "component---src-templates-luogo-template-jsx": () => import("./../../../src/templates/luogo-template.jsx" /* webpackChunkName: "component---src-templates-luogo-template-jsx" */),
  "component---src-templates-news-template-jsx": () => import("./../../../src/templates/news-template.jsx" /* webpackChunkName: "component---src-templates-news-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/page-template.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/post-template.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-progetti-template-jsx": () => import("./../../../src/templates/progetti-template.jsx" /* webpackChunkName: "component---src-templates-progetti-template-jsx" */)
}

