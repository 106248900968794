import React from "react"
import { motion } from "framer-motion"
import { useWpColophon } from "../../hooks/wp/useWpColophon"
import { Link } from "gatsby"

const bannerAnimations = {
  initial: {
    y: "0%",
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "tween",
    },
  },
  scrolled: {
    y: "100%",
    opacity: 0,
    transition: {
      duration: 0.5,
      type: "tween",
    },
  },
}

const NewsletterBanner = ({ isScrolled, scrollDirection }) => {
  const { wpPage } = useWpColophon()

  return (
    <>
      {wpPage.colophon.newsletterBanner.active && (
        <motion.div
          className="h-[30px] md:h-[60px] w-full flex flex-row items-center bg-yellow-500 sticky bottom-0 z-10"
          variants={bannerAnimations}
          animate={
            isScrolled && scrollDirection === "down" ? "scrolled" : "initial"
          }
        >
          <div className="px-4 md:px-12 flex flex-row justify-between w-full">
            <span>{wpPage.colophon.newsletterBanner.text}</span>
            <button className="md:text-xl  text-gray-300">
              {wpPage.colophon.newsletterBanner.destination.indexOf("densa") >
              -1 ? (
                <Link
                  to={wpPage.colophon.newsletterBanner.destination}
                  className="border border-slate-100 hover:text-slate-100 hover:bg-transparent px-2 md:px-8 md:py-1 rounded-[30px] bg-slate-100 text-black duration-300"
                >
                  {wpPage.colophon.newsletterBanner.linkText}
                </Link>
              ) : (
                <a
                  href={wpPage.colophon.newsletterBanner.destination}
                  className="border border-slate-100 hover:text-slate-100 hover:bg-transparent px-2 md:px-8 md:py-1 rounded-[30px] bg-slate-100 text-black duration-300"
                >
                  {wpPage.colophon.newsletterBanner.linkText}
                </a>
              )}
            </button>
          </div>
        </motion.div>
      )}
    </>
  )
}

export default NewsletterBanner
