import { useStaticQuery, graphql } from "gatsby"

export const useWpMenu = () => {
  const data = useStaticQuery(graphql`
    query wpMenuQuery {
      wpMenu {
        menuItems {
          nodes {
            connectedNode {
              node {
                id
                ... on WpPage {
                  id
                  uri
                  slug
                  title
                  content
                  pageDescription {
                    pageDescription
                  }
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return data
}
