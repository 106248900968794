import React, { useState, useEffect } from "react"
import Header from "./Header"
import Footer from "./Footer"
import AnimatedMain from "./AnimatedMain"
import NewsletterBanner from "./NewsletterBanner"
import { StateContext } from "./StateContext"
import { AnimatePresence, useScroll } from "framer-motion"

const Layout = ({ location, children }) => {
  const [isSpecialPage, setIsSpecialPage] = useState(
    location.pathname.includes("luogo") ? true : false
  )

  useEffect(() => {
    location.pathname.includes("luogo")
      ? setIsSpecialPage(true)
      : setIsSpecialPage(false)
  }, [location.pathname])

  StateContext.isSpecialPage = isSpecialPage

  const [isScrolled, setIsScrolled] = useState(false)
  const [scrollDirection, setScrollDirection] = useState(null)
  const { scrollYProgress } = useScroll()

  useEffect(() => {
    return scrollYProgress.onChange(latest => {
      if (latest > 0) {
        scrollYProgress.prev > latest
          ? setScrollDirection("up")
          : setScrollDirection("down")
        setIsScrolled(true)
        StateContext.isScrolled = true
      } else {
        setIsScrolled(false)
        StateContext.isScrolled = false
        setScrollDirection(null)
      }
    })
  }, [scrollYProgress])

  return (
    <>
      <StateContext.Provider value={StateContext}>
        <Header
          location={location}
          isScrolled={isScrolled}
          scrollDirection={scrollDirection}
        />
        <AnimatePresence mode="wait">
          <AnimatedMain location={location}>
            {React.cloneElement(children, {
              location: location,
            })}
          </AnimatedMain>
        </AnimatePresence>
        <NewsletterBanner
          isScrolled={isScrolled}
          scrollDirection={scrollDirection}
        />
        <Footer />
      </StateContext.Provider>
    </>
  )
}

export default Layout
