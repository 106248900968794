import React from "react"
import { motion } from "framer-motion"
import { StateContext } from "./StateContext"

const pageTransition = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.45 },
  },
  exit: { opacity: 0, transition: { duration: 0.4 } },
}

const AnimatedMain = ({ location, children }) => {
  return (
    <motion.main
      className="min-h-[100vh]"
      key={location.pathname}
      variants={pageTransition}
      initial="initial"
      animate="animate"
      exit="exit"
      style={{
        backgroundColor: StateContext.isSpecialPage ? "#343434" : "white",
      }}
    >
      {children}
    </motion.main>
  )
}

export default AnimatedMain
