import React, { useState, useEffect, useCallback } from "react"
import { Link } from "gatsby"
import LogoBlack from "../../../assets/logo/logo-black.svg"
import LogoWhite from "../../../assets/logo/logo-white.svg"
import Hamburger from "./Hamburger"
import OverlayMenu from "./OverlayMenu"
import { AnimatePresence, motion } from "framer-motion"
import { StateContext } from "./StateContext"
import CrossWhite from "../../../assets/cross-white.svg"
import SearchButton from "./SearchButton"
import { navigate } from "gatsby"

const navbarAnimations = {
  initial: {
    y: "0%",
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "tween",
    },
  },
  scrolled: {
    y: "-100%",
    opacity: 0,
    transition: {
      duration: 0.5,
      type: "tween",
    },
  },
}

const Header = ({ location, isScrolled, scrollDirection }) => {
  const [menuState, setMenuState] = useState(false)
  useEffect(() => {
    setMenuState(false)
  }, [location.pathname])

  // handle ESC key to close menu
  const keyNavigation = useCallback(
    event => {
      if (event.keyCode === 27) {
        setMenuState(false)
      }
    },
    [setMenuState]
  )
  useEffect(() => {
    document.addEventListener("keydown", keyNavigation, false)
    return () => {
      document.removeEventListener("keydown", keyNavigation, false)
    }
  })
  // ---

  const [searchState, setSearchState] = useState(false)
  const [query, setQuery] = useState("")
  const handleSubmit = event => {
    let dest = "/cerca/"
    const urlWithQuery = query.replace(/\s+/g, "+")
    dest = dest + "?q=" + urlWithQuery
    event.preventDefault()
    navigate(dest)
    setMenuState(false)
    setSearchState(false)
  }

  const handleInputChange = val => {
    setQuery(val)
  }

  return (
    <>
      <motion.div
        className={`fixed top-0 left-0 w-full z-10 ${
          StateContext.isSpecialPage ? "bg-[#343434]" : "bg-white"
        }`}
        variants={navbarAnimations}
        animate={
          !menuState && isScrolled && scrollDirection === "down"
            ? "scrolled"
            : "initial"
        }
      >
        <header className="relative z-[99]">
          <nav className="flex items-center justify-between py-3 md:p-8 px-4 md:px-12">
            <Link to="/">
              {StateContext.isSpecialPage ? <LogoWhite /> : <LogoBlack />}
            </Link>
            <div className="flex items-center gap-x-4">
              <SearchButton
                menuState={menuState}
                searchState={searchState}
                setSearchState={setSearchState}
              />
              <Hamburger menuState={menuState} setMenuState={setMenuState} />
            </div>
          </nav>
        </header>
        <AnimatePresence>
          {menuState && (
            <OverlayMenu location={location} menuState={menuState} />
          )}
        </AnimatePresence>
      </motion.div>

      <AnimatePresence>
        {searchState && (
          <motion.div
            initial={{ opacity: 0, height: "0" }}
            animate={{ opacity: 1, height: "calc(100vh - 30px)" }}
            exit={{ opacity: 0, height: "0" }}
            transition={{ duration: 0.4 }}
            className="flex flex-col items-center justify-center fixed md:top-4 md:left-4 w-full md:w-[calc(100vw-2em)] md:h-[calc(100vh-2em)] md:rounded-xl bg-gray-800 px-4 md:p-4 md:px-8 z-[99] overflow-hidden "
          >
            <button
              className="absolute right-6 sm:right-16 md:right-8 top-3 "
              onClick={() => setSearchState(false)}
            >
              <CrossWhite width={20} />
            </button>
            <form
              className="text-white font-light"
              onSubmit={ev => handleSubmit(ev)}
            >
              <label>
                <input
                  type="text"
                  name="search"
                  placeholder={"Cerca per parola chiave"}
                  value={query}
                  onChange={ev => handleInputChange(ev.target.value)}
                  className="p-4 md:px-20 bg-transparent border-b-2 border-dkGray focus:outline-none "
                />
              </label>
            </form>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default Header
