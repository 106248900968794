import { useStaticQuery, graphql } from "gatsby"

export const useWpGeneralInfo = () => {
  const data = useStaticQuery(graphql`
    query wpGeneralInfoQuery {
      wpPage(slug: { eq: "about" }) {
        id
        generalInfo {
          shortMenuDefaultText
        }
      }
    }
  `)

  return data
}
