import { useStaticQuery, graphql } from "gatsby"

export const useWpColophon = () => {
  const data = useStaticQuery(graphql`
    query wpColophon {
      wpPage(slug: { eq: "homepage" }) {
        id
        colophon {
          address
          contacts
          businessInfo
          newsletterBanner {
            active
            destination
            linkText
            text
          }
        }
      }
    }
  `)

  return data
}
